import React from "react";
import {
	Box,
	Grid,
	Button,
	Divider,
	TextField,
	Typography,
	Autocomplete,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import {
	makeStyles,
	withStyles
} from "@mui/styles";
import {} from "@mui/icons-material";
import {
	palette
} from "../../../../../theme/common";
import agent from "../../../../../agent/agent";
import {
	PhotoImage, PhotoImageMini
} from "../DialogCreateWork";

class DialogRecomendationManager extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			form: {},
			isOpen: false,
			isRecommendation: false,

			stepForm: "works",

			onSubmit: null,
			onDeleteWorkFile: null,
		};
	}

	open = ({initForm: editInitForm, onSubmit, onDeleteWorkFile, isRecommendation}) => {
		const adminNote = String(editInitForm?.admin_note || "").split("<br>");
		editInitForm.items = [...(editInitForm.items || [])].map((_item, index) => {
			return {
				..._item,
				adminNote: adminNote[index] || ""
			}
		})

		if (editInitForm && (editInitForm?.items || []).length > 0) {
			const allFiles = editInitForm?.files || [];
			editInitForm.items = [...(editInitForm?.items || [])].map((_item) => {
				return {
					..._item,
					images: allFiles.filter((allF) => (_item?.images || []).find((t) => allF.fileId === t.id))
				}
			})
		};

		console.log('editInitForm: ', editInitForm);
		console.log('isRecommendation: ', isRecommendation);

		this.setState({
			form: {...editInitForm},
			isOpen: true,
			isRecommendation,

			onSubmit,
			onDeleteWorkFile
		})
	}
	close = () => {
		this.setState({
			form: {},
			isOpen: false,
			isRecommendation: false,

			stepForm: "works",

			onSubmit: null,
			onDeleteWorkFile: null,
		})
	}

	changeForm = (form) => {
		this.setState({ form });
	}
	changeFormItems = (index, event, value) => {
		let form = {...this.state.form};
		form.items[index]['code'] = value || null;
		form.items[index]['code_id'] = value?.id || null;
		form.items[index]['is_create_connection'] = Boolean(form.items[index]?.description);
		this.setState({ form });
	}
	changeFormItemName = (index, event) => {
		const { name, value } = event.target;
		let form = {...this.state.form};
		form.items[index][name] = value;
		this.setState({ form });
	}
	changeStepForm = () => {
		this.submitForm();
	}

	addItemToForm = () => {
		const { form } = this.state;

		let items = [...(form.items || [])];
		items.push({
			images: [],
			adminNote: "",
			description: "",

			isCreateFromAdmin: true
		});

		this.changeForm({
			...form,
			items
		});
	}

	removeImage = (fileId, callback) => {
		const serviceId = this.state?.form?.id || 0;
		this.state.onDeleteWorkFile(fileId, serviceId, callback);
	}

	submitForm = () => {
		const { form } = this.state;

		const body = {
			...form,
			admin_note: [...(form?.items || [])].map((t) => t.adminNote).join('<br>')
		};

		// return
		this.state.onSubmit(body, true);
	}

	_title = () => {
		const { isRecommendation } = this.state;
		if (isRecommendation) {
			return 'Редактирование перечня рекомендация'
		}
		return 'Редактирование перечня работ'
	}
	_addItemLabel = () => {
		const {isRecommendation} = this.state;
		if (isRecommendation) {
			return "Добавить рекомендацию"
		}
		return "Добавить работу"
	}

	render() {
		const {
			classes
		} = this.props;
		const {
			form,
			isOpen,
			stepForm
		} = this.state;

		return (
			<Dialog
				open={isOpen}
				fullWidth
				className={classes.dialog}
			>
				<DialogTitle className={classes.dialogTitle}>
					<Typography variant="h3">{this._title()}</Typography>
				</DialogTitle>

				<DialogContent>
					<Box mt={2}/>
					<Box className={classes.section}>
						<Typography className={classes.sectionTitle}>Перечень</Typography>
						<Grid container spacing={2}>
							{(form?.items || []).map((_item, index) => <WorkRowItem
								item={_item}
								onChange={this.changeFormItems.bind(this, index)}
								onChangeName={this.changeFormItemName.bind(this, index)}
								onEditImages={this.changeFormItemName.bind(this, index)}
								onDeleteImage={this.removeImage}
							/>)}
						</Grid>
					</Box>
				</DialogContent>

				<DialogActions>
					<Box px={2} pb={1} sx={{ flex: 1 }}>
						<Grid container spacing={2} justifyContent="space-between" className={classes.dialogActionsGrid}>
							<Grid item>
								<Button
									size="small"
									variant="outlined"
									className={classes.dialogActionsButton}
									onClick={this.close}
								>
									Закрыть
								</Button>
							</Grid>
							<Grid item>
								<Button
									size="small"
									variant="outlined"
									className={classes.dialogActionsButton}
									onClick={this.addItemToForm}
								>
									{this._addItemLabel()}
								</Button>
							</Grid>
							<Grid item>
								<Button
									size="small"
									variant="contained"
									className={classes.dialogActionsButton}
									onClick={this.changeStepForm.bind(null, 'next')}
								>Сохранить</Button>
							</Grid>
						</Grid>
					</Box>
				</DialogActions>
			</Dialog>
		)
	}
};
const WorkRowItem = (props) => {
	const {
		item,
		onChange,
		onEditImages,
		onChangeName,
		onDeleteImage
	} = props;
	const classes = useStyles();
	const isError = !Boolean(item?.code);

	const handleChangeImage = ({ target }) => {
		onEditImages({
			target: {
				name: "images",
				value: target.value
			}
		})
	}

	return (
		<Grid item xs={12}>
			<Box
				px={2}
				py={1}
				bgcolor="#F4F6F8"
				borderRadius={2}
				border="1px solid #F4F6F8"
				borderColor={Boolean(isError) ? palette.error.main : '#F4F6F8'}
			>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Grid container spacing={2} className={classes.gridRowNowrap}>
							<Grid item xs={12} sm={5}>
								<Typography variant="caption" textAlign="left" justifyContent="flex-start" mb={1}>
									Комментарий от механика
								</Typography>
								<Typography variant="h4">{ item?.description }</Typography>
							</Grid>
							<Grid item className="remove-mobile">
								<Divider orientation="vertical" sx={{ borderColor: "#152435", opacity: 0.2 }}/>
							</Grid>
							<Grid item xs={12} sm={7}>
								<Typography variant="caption" textAlign="left" justifyContent="flex-start" mb={1}>
									Нормативная работа
								</Typography>
								<AutocompleteService
									value={item.code}
									key={item.description}
									onChange={onChange}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Divider orientation="horizontal" sx={{ borderColor: "#152435", opacity: 0.2 }}/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="caption" textAlign="left" justifyContent="flex-start" mb={1}>
							Рекомендация для клиента
						</Typography>
						<TextField
							value={item.adminNote}
							size="small"
							name="adminNote"
							placeholder="Введите"
							rows={4}
							fullWidth={true}
							multiline={true}
							onChange={onChangeName}
						/>
					</Grid>
					<Grid item xs={12}>
						<Divider orientation="horizontal" sx={{ borderColor: "#152435", opacity: 0.2 }}/>
					</Grid>
					<Grid item xs={12}>
						<PhotoImageMini
							disabledCreate={!Boolean(item?.isCreateFromAdmin)}
							onChange={handleChangeImage}
							images={item.images || []}
							onDelete={onDeleteImage}
						/>
					</Grid>
				</Grid>
			</Box>
		</Grid>
	)
};

let timeoutGetResult = null;
const AutocompleteService = (props) => {
	const {
		value,
		onChange,

		...otherProps
	} = props;
	const classes = useStyles();

	const [search, setSearch] = React.useState('');
	const [options, setOptions] = React.useState([]);
	const [isLoading, setLoading] = React.useState(false);

	const handleGetOptions = async (_search) => {
		const res = await agent(`/codes?search=${ _search }`).then((res) => {
			return res?.data?.data || []
		}).catch(() => {
			return []
		});
		setOptions(res);
		setLoading(false)
	}
	const handleChangeSearch = (event, _search) => {
		if (event?.type === 'blur') {
			setOptions([])
			return
		}

		clearTimeout(timeoutGetResult);
		setOptions([]);
		setLoading(String(_search || '').length >= 2)
		setSearch(_search);
		timeoutGetResult = setTimeout(async () => {
			await handleGetOptions(_search);
		}, 1500)
	}

	const _getOptionLabel = (option) => {
		return option?.name || ''
	}

	return (
		<Autocomplete
			{...otherProps}

			value={value}

			options={options}
			variant="outlined"
			fullWidth
			autoComplete
			disablePortal={true}
			loading={isLoading}
			limitTags={1}
			noOptionsText="По вашему запросу ничего не найдено"

			renderInput={(params) => <TextField
				{...params}
				fullWidth
				size="small"
			/>}

			filterOptions={(options) => [...options]}
			getOptionLabel={_getOptionLabel}
			onInputChange={handleChangeSearch}

			classes={{
				option: classes.autocomplete
			}}

			onChange={onChange}
		/>
	)
};

const styles = {
	dialog: {
		"& .MuiPaper-root": {
			maxWidth: 1200,
			overflow: "initial"
		}
	},
	dialogTitle: {
		background: palette.primary.main,
		"& .MuiTypography-root": {
			textAlign: 'left',
			color: palette.primary.contrastText,
		}
	},

	section: {},
	sectionTitle: {
		marginBottom: 16,

		fontSize: 22,
		lineHeight: "24px",
		color: "#152435",
		fontWeight: "600",

		"@media(max-width: 1023px)": {
			marginBottom: 8,
			fontSize: 16,
			lineHeight: "18px",
		},
	},

	dialogActionsGrid: {
		"@media(max-width: 639px)": {
			flexDirection: "column",
			gap: 8,
			marginTop: 16,
			"& > *": {
				display: "flex",
				width: "calc(100% + 16px)",
				padding: "0!important",
			},
			"& > * > *": {
				width: "100%"
			},
		}
	},
};
DialogRecomendationManager = withStyles(styles)(DialogRecomendationManager);

const useStyles = makeStyles(() => ({
	autocomplete: {
		color: "#152435",
		fontSize: "16px",
		lineHeight: "20px",
	},
	gridRowNowrap: {
		flexWrap: 'nowrap',
		"@media(max-width: 600px)": {
			flexWrap: "wrap",
			"& .remove-mobile": {
				display: "none"
			}
		}
	},
}));

export default DialogRecomendationManager
